import React, { useState } from "react";
import { RouteComponentProps } from "react-router";
import { NavLink } from "react-router-dom";

import {
  ConstrainedLink,
  LinkOwner,
  LinkPaid,
  isOwner,
  isStaff,
} from "app/componentConstraints";
import { screenSmall } from "app/js/util";
import Logo from "app/components/Icons/Logo";
import User from "app/components/User/User";

import styles from "./SideBar.scss";

const SideBar: React.FC<Partial<Pick<RouteComponentProps, "history">>> = ({
  history,
}) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <nav className={`${styles.sidebar} ${mobileMenuOpen ? styles.open : ""}`}>
      <div className={styles.nav}>
        <div
          className={styles.pointer}
          onClick={() => {
            if (!screenSmall()) {
              history.push("/");
              return;
            }
            setMobileMenuOpen((prev) => !prev);
          }}
        >
          <Logo className={styles.logo} />
        </div>
        <ul>
          <li>
            <NavLink to="/" exact activeClassName="active">
              Home
            </NavLink>
          </li>
          <LinkOwner to="/actionscripts" activeClassName="active">
            Actionscripts
          </LinkOwner>
          <LinkOwner to="/streams" activeClassName="active">
            Cameras
          </LinkOwner>
          <LinkOwner to="/data-sets" activeClassName="active">
            Data Sets
          </LinkOwner>
          <ConstrainedLink
            userConstraint={(user) => isOwner(user) || isStaff(user)}
            to="/dataset-views"
            activeClassName="active"
          >
            Dataset Views
          </ConstrainedLink>
          <LinkOwner to="/detectors" activeClassName="active">
            Models
          </LinkOwner>
          <li>
            <NavLink to="/labelling-jobs" activeClassName="active">
              Labelling Jobs
            </NavLink>
          </li>
          <LinkPaid to="/processing-tasks" activeClassName="active">
            Processing Tasks
          </LinkPaid>
          <LinkOwner to="/app-configs" activeClassName="active">
            App Configs
          </LinkOwner>
          <hr />
          <li>
            <NavLink to="/settings/profile" activeClassName="active">
              Settings
            </NavLink>
          </li>
          <LinkOwner to={`/settings/team`} activeClassName="active">
            Team Settings
          </LinkOwner>
        </ul>
        <hr />
        <User />
      </div>
    </nav>
  );
};
export default SideBar;
