import React from "react";
import Api from "app/js/api";
import { AppInstance, VisualSampleSetFilterChoices } from "app/js/types";
import { useSafeState } from "app/js/hooks";
import styles from "app/pages/LabellingJobs/LabellingJobDetail/LabellingJobFilter/CreatorFilterChoiceLabel.scss";

interface ApplicationInstanceHook {
  applicationInstance: AppInstance;
  loading: boolean;
}

const useApplicationInstance = (
  applicationInstanceId: number | null = null,
): ApplicationInstanceHook => {
  const [data, setData] = useSafeState<ApplicationInstanceHook>({
    applicationInstance: null,
    loading: true,
  });
  React.useEffect(() => {
    if (applicationInstanceId) {
      setData({
        applicationInstance: null,
        loading: true,
      });
      Api.applicationInstance(applicationInstanceId)
        .show()
        .then((response) => {
          setData({
            applicationInstance: response.data,
            loading: false,
          });
        });
    } else {
      setData({
        applicationInstance: null,
        loading: false,
      });
    }
  }, [applicationInstanceId, setData]);
  return data;
};

const CreatorFilterChoiceLabel: React.FC<VisualSampleSetFilterChoices> = (
  filterChoice,
) => {
  const { applicationInstance, loading } = useApplicationInstance(
    filterChoice.application_instance_id,
  );
  if (filterChoice.application_instance_id) {
    if (!loading) {
      return (
        <div className={styles.container}>
          <label className={styles.type}>
            Labels requested by App Instance
          </label>
          <span className={styles.value}>
            {filterChoice.application_instance_name} (
            {applicationInstance?.application})
          </span>
        </div>
      );
    } else {
      return <div className={styles.container}>Loading ...</div>;
    }
  } else {
    return (
      <div className={styles.container}>
        <label className={styles.type}>Labels requested by User</label>
        <span className={styles.value} title={filterChoice.user_email}>
          {filterChoice.user_name}
        </span>
      </div>
    );
  }
};

export default CreatorFilterChoiceLabel;
